export const Tooltip = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 32 32"
      className={className}>
      <g clipPath="url(#a)">
        <path
          fill="#B9D232"
          d="M16 2.667C8.64 2.667 2.667 8.64 2.667 16S8.64 29.333 16 29.333 29.333 23.36 29.333 16 23.36 2.667 16 2.667Zm1.333 20h-2.666v-8h2.666v8Zm0-10.667h-2.666V9.333h2.666V12Z"
        />
        <path
          fill="#010101"
          d="M17.333 22.667h-2.666v-8h2.666v8Zm0-10.667h-2.666V9.333h2.666V12Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h32v32H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}
