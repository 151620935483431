import styles from "./tooltip-label.module.css"
import Close from "@/components/common/icons/close"
import classNames from "classnames"

export default function Tooltip({
  title,
  text,
  position,
  close,
  className,
  html = false
}) {
  return (
    <div className={classNames(styles.wrapper, position, className)}>
      <div className="close" onClick={close}>
        <Close color="white" />
      </div>
      {html && <div dangerouslySetInnerHTML={{ __html: html }}></div>}
      {!html && (
        <>
          <strong>{title}</strong>
          <p>{text}</p>
        </>
      )}
    </div>
  )
}
