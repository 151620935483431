import { useEffect, useState } from "react"
import tailwindConfig from "../../tailwind.config.js"

export default function useBreakpoint() {
  const [isMounted, setMounted] = useState(false)
  const [breakpoint, setBreakpoint] = useState(null)

  const handleResize = () => {
    const name = getBreakpoint(window.innerWidth)
    if (breakpoint !== name) {
      setBreakpoint(name)
    }
  }

  useEffect(() => {
    handleResize()
    setMounted(true)

    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return { isMounted, breakpoint, isLargerThan }
}

export const getOrderedScreens = () => {
  const { screens } = tailwindConfig.theme

  const orderedScreens = Object.keys(screens).sort(
    (a, b) => parseInt(screens[a]) - parseInt(screens[b])
  )

  return orderedScreens
}

export const isLargerThan = (test, current) => {
  const screens = getOrderedScreens()

  const testIndex = screens.indexOf(test)
  const currentIndex = screens.indexOf(current)

  if (testIndex === -1 || currentIndex === -1) {
    return null
  }

  return testIndex < currentIndex
}

export const isSmallerThan = (test, current) => {
  const screens = getOrderedScreens()

  const testIndex = screens.indexOf(test)
  const currentIndex = screens.indexOf(current)

  if (testIndex === -1 || currentIndex === -1) {
    return null
  }

  return testIndex > currentIndex
}

export const getBreakpoint = (width) => {
  const { screens } = tailwindConfig.theme
  let [breakpoint] = getOrderedScreens()

  Object.keys(screens).forEach((key) => {
    if (width >= parseInt(screens[key])) {
      breakpoint = key
    }
  })

  return breakpoint
}
