const plugin = require("tailwindcss/plugin")

module.exports = {
  content: [
    "./components/**/*.{js,jsx}",
    "./pages/**/*.{js,jsx}",
    "./lib/**/*.{js,jsx}"
  ],
  safelist: [
    // FIXME This list should be editor.css, colors.css?
    // all classes what may come from editor
    "has-drop-cap",
    "has-text-align-center",
    "is-style-article-lead",
    "is-style-post-lead",
    "highlight",
    "is-style-sans",
    "is-style-serif",
    "is-style-object-tag",
    "has-dark-color",
    "has-white-color",
    "core-paragraph",
    "object-tag",
    "theme-green",
    "theme-lime",
    "theme-white"
  ],
  presets: [],
  theme: {
    screens: {
      xsm: "400px",
      sm: "640px",
      md: "768px",
      ml: "860px",
      lg: "1024px",
      xl: "1280px",
      "2xl": "1536px" // ???
    },
    // Do not add colors willy-nilly, consult with designer
    // Always use uppercase HEX
    // Do not add specific colors with alpha, instead use
    // the alpha syntax, for example bg-summer-green-100/20
    // which will give you an alpha of 0.2
    colors: {
      transparent: "transparent",
      current: "currentColor",
      // From original stylesheet
      "summer-green": {
        5: "#FBFDF5",
        20: "#F8FAEA",
        23: "#ECF2CA",
        25: "#EDF4CC",
        50: "#DCE898",
        75: "#CBDE66",
        100: "#B9D232"
      },
      green: {
        5: "#F8FBF5",
        10: "#F1F7EB",
        25: "#DCEBCE",
        50: "#B9D79D",
        75: "#96C36D",
        100: "#73AF3C"
      },
      "dark-cyan": {
        5: "#F2FAF9",
        25: "#BFE5E3",
        50: "#7FCAC8",
        75: "#40B1AD",
        100: "#009691"
      },
      black: {
        5: "#F3F3F3",
        25: "#C3C3C3",
        50: "#878787",
        75: "#4C4C4C",
        100: "#101010", // Should be used where the intent is black
        110: "#000000" // Added since there are actual black-black elements in the design
      },
      white: "#ffffff",
      // Additional
      red: {
        10: "#F8E8E8",
        100: "#FF2B15",
        110: "#AF3E3C"
      },
      // Campaign
      // All campaign specific colors should be prepended with campaign-
      "campaign-yellow": {
        30: "#fcf9c8",
        50: "#F9F4A3",
        100: "#F0E60A",
        110: "#E6DA09"
      },
      "campaign-light-lime": {
        75: "#f7ffb9",
        100: "#ECFF61",
        125: "#e4ff1b"
      },
      "campaign-forest-green": {
        100: "#213515"
      }
    },
    spacing: {
      px: "1px",
      0: "0px",
      0.5: "0.125rem",
      1: "0.25rem",
      1.4: "0.313rem",
      1.5: "0.375rem",
      1.7: "0.438rem",
      2: "0.5rem",
      2.4: "0.563rem",
      2.5: "0.625rem",
      2.6: "0.688rem",
      3: "0.75rem",
      3.5: "0.875rem",
      3.6: "0.938rem",
      4: "1rem",
      4.5: "1.125rem",
      5: "1.25rem",
      5.5: "1.375rem",
      6: "1.5rem",
      6.5: "1.563rem",
      6.6: "1.625rem",
      6.7: "1.688rem",
      7: "1.75rem",
      7.5: "1.875rem",
      8: "2rem",
      8.5: "2.125rem",
      9: "2.25rem",
      9.5: "2.313rem",
      10: "2.5rem",
      11: "2.75rem",
      11.5: "2.813rem",
      12: "3rem",
      13: "3.125rem",
      13.5: "3.25rem",
      14: "3.5rem",
      15: "3.75rem",
      16: "4rem",
      17: "4.375rem",
      18: "4.5rem",
      20: "5rem",
      22: "5.625rem",
      24: "6rem",
      25: "6.25rem",
      26: "6.875rem",
      28: "7rem",
      29: "7.25rem",
      30: "7.5rem",
      32: "8rem",
      32.5: "8.125rem",
      32.6: "8.25rem",
      33: "8.438rem",
      34: "8.5rem",
      35: "8.75rem",
      36: "9rem",
      38: "9.5rem",
      39: "9.75rem",
      40: "10rem",
      44: "11rem",
      45: "11.25rem",
      48: "12rem",
      49: "12.25rem",
      50: "12.5rem",
      52: "13rem",
      54: "13.5rem",
      56: "14rem",
      57: "14.063rem",
      60: "15rem",
      64: "16rem",
      69: "17.25rem",
      72: "18rem",
      73: "18.188rem",
      80: "20rem",
      84: "22.25rem",
      85: "22.5rem",
      96: "24rem",
      "icon-sm": "32px",
      icon: "40px",
      "icon-lg": "50px",
      "icon-xl": "60px",
      "icon-xxl": "80px",
      "2/3": "66.666667%",
      "3/4": "75%",
      full: "100%"
    },
    columns: {
      1: "1",
      2: "2",
      3: "3",
      4: "4"
    },
    animation: {
      none: "none",
      spin: "spin 1s linear infinite",
      ping: "ping 1s cubic-bezier(0, 0, 0.2, 1) infinite",
      pulse: "pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite",
      bounce: "bounce 1s infinite"
    },
    backdropBlur: ({ theme }) => theme("blur"),
    backdropBrightness: ({ theme }) => theme("brightness"),
    backdropContrast: ({ theme }) => theme("contrast"),
    backdropGrayscale: ({ theme }) => theme("grayscale"),
    backdropHueRotate: ({ theme }) => theme("hueRotate"),
    backdropInvert: ({ theme }) => theme("invert"),
    backdropOpacity: ({ theme }) => theme("opacity"),
    backdropSaturate: ({ theme }) => theme("saturate"),
    backdropSepia: ({ theme }) => theme("sepia"),
    backgroundColor: (theme) => theme("colors"),
    backgroundImage: {
      none: "none",
      "gradient-to-t": "linear-gradient(to top, var(--tw-gradient-stops))",
      "gradient-to-tr":
        "linear-gradient(to top right, var(--tw-gradient-stops))",
      "gradient-to-r": "linear-gradient(to right, var(--tw-gradient-stops))",
      "gradient-to-br":
        "linear-gradient(to bottom right, var(--tw-gradient-stops))",
      "gradient-to-b": "linear-gradient(to bottom, var(--tw-gradient-stops))",
      "gradient-to-bl":
        "linear-gradient(to bottom left, var(--tw-gradient-stops))",
      "gradient-to-l": "linear-gradient(to left, var(--tw-gradient-stops))",
      "gradient-to-tl": "linear-gradient(to top left, var(--tw-gradient-stops))"
    },
    backgroundOpacity: (theme) => theme("opacity"),
    backgroundPosition: {
      bottom: "bottom",
      center: "center",
      left: "left",
      "left-bottom": "left bottom",
      "left-top": "left top",
      right: "right",
      "right-bottom": "right bottom",
      "right-top": "right top",
      top: "top"
    },
    backgroundSize: {
      auto: "auto",
      cover: "cover",
      contain: "contain"
    },
    borderColor: (theme) => ({
      ...theme("colors"),
      DEFAULT: theme("colors.gray.border", "currentColor")
    }),
    borderOpacity: (theme) => theme("opacity"),
    borderRadius: {
      none: "0px",
      sm: "0.125rem",
      DEFAULT: "0.25rem",
      md: "0.375rem",
      lg: "0.5rem",
      xl: "0.75rem",
      "2xl": "1rem",
      "3xl": "1.5rem",
      full: "9999px"
    },
    borderWidth: {
      DEFAULT: "1px",
      0: "0px",
      2: "2px",
      3: "3px",
      4: "4px",
      6: "6px",
      8: "8px",
      9: "9px",
      10: "10px"
    },

    boxShadow: {
      sm: "0 1px 2px 0 rgba(0, 0, 0, 0.05)",
      "md-sm": "0 2px 4px 0 rgba(0, 0, 0, 0.1)",
      DEFAULT:
        "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)",
      md: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
      lg: "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)",
      xl: "0 4px 24px 0px rgba(0, 0, 0, 0.1)",
      "2xl": "0 25px 50px -12px rgba(0, 0, 0, 0.25)",
      inner: "inset 0 2px 4px 0 rgba(0, 0, 0, 0.06)",
      none: "none"
    },
    container: {
      center: true,
      padding: {
        DEFAULT: "1rem",
        sm: "2.5rem",
        md: "2.5rem",
        lg: "4.375rem",
        xl: "4.375rem",
        "2xl": "4.375rem"
      }
    },
    cursor: {
      auto: "auto",
      default: "default",
      pointer: "pointer",
      wait: "wait",
      text: "text",
      move: "move",
      zoom: "zoom-in",
      "not-allowed": "not-allowed"
    },
    divideColor: (theme) => theme("borderColor"),
    divideOpacity: (theme) => theme("borderOpacity"),
    divideWidth: (theme) => theme("borderWidth"),
    fill: {
      current: "currentColor",
      white: "#FFFFFF",
      black: "#101010"
    },
    flex: {
      1: "1 1 0%",
      auto: "1 1 auto",
      initial: "0 1 auto",
      none: "none"
    },
    flexGrow: {
      0: "0",
      DEFAULT: "1"
    },
    flexShrink: {
      0: "0",
      DEFAULT: "1"
    },
    flexBasis: {
      auto: "auto",
      "1/2": "50%",
      "1/3": "33.333333%",
      "2/3": "66.666667%",
      "1/4": "25%",
      "2/4": "50%",
      "3/4": "75%",
      "1/5": "20%",
      "2/5": "40%",
      "3/5": "60%",
      "4/5": "80%",
      "1/6": "16.666667%",
      "2/6": "33.333333%",
      "3/6": "50%",
      "4/6": "66.666667%",
      "5/6": "83.333333%",
      "1/12": "8.333333%",
      "2/12": "16.666667%",
      "3/12": "25%",
      "4/12": "33.333333%",
      "5/12": "41.666667%",
      "6/12": "50%",
      "7/12": "58.333333%",
      "8/12": "66.666667%",
      "9/12": "75%",
      "10/12": "83.333333%",
      "11/12": "91.666667%",
      full: "100%"
    },
    fontFamily: {
      sans: [
        "Roboto",
        "ui-sans-serif",
        "system-ui",
        "-apple-system",
        "BlinkMacSystemFont",
        '"Segoe UI"',
        "Roboto",
        '"Helvetica Neue"',
        "Arial",
        '"Noto Sans"',
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
        '"Noto Color Emoji"'
      ],
      serif: [
        "Roboto Slab",
        "ui-serif",
        "Georgia",
        "Cambria",
        '"Times New Roman"',
        "Times",
        "serif"
      ],
      mono: [
        "ui-monospace",
        "SFMono-Regular",
        "Menlo",
        "Monaco",
        "Consolas",
        '"Liberation Mono"',
        '"Courier New"',
        "monospace"
      ]
    },
    fontSize: {
      xs: ["0.75rem", { lineHeight: "1rem" }],
      sm: ["0.875rem", { lineHeight: "1.25rem" }],
      base: ["1rem", { lineHeight: "1.5rem" }],
      lg: ["1.125rem", { lineHeight: "1.75rem" }],
      xl: ["1.25rem", { lineHeight: "1.75rem" }],
      "1.5xl": ["1.375rem", { lineHeight: "1.875rem" }],
      "2xl": ["1.5rem", { lineHeight: "2rem" }],
      "2.3xl": ["1.625rem", { lineHeight: "2.25rem" }],
      "2.5xl": ["1.75rem", { lineHeight: "2.5rem" }],
      "3xl": ["1.875rem", { lineHeight: "2.25rem" }],
      "3.5xl": ["2rem", { lineHeight: "2.625rem" }],
      "4xl": ["2.25rem", { lineHeight: "2.5rem" }],
      "4.5xl": ["2.375rem", { lineHeight: "3.25rem" }],
      "5xl": ["3rem", { lineHeight: "3.875rem" }],
      "6xl": ["3.75rem", { lineHeight: "1.2em" }],
      "7xl": ["4.5rem", { lineHeight: "1.2em" }],
      "7.5xl": ["5rem", { lineHeight: "1.2em" }],
      "8xl": ["6rem", { lineHeight: "1.2em" }],
      "9xl": ["8rem", { lineHeight: "1.2em" }]
    },
    fontWeight: {
      thin: "100",
      extralight: "200",
      light: "300",
      normal: "400",
      medium: "500",
      semibold: "600",
      bold: "700",
      extrabold: "800",
      black: "900"
    },
    gap: (theme) => theme("spacing"),
    gradientColorStops: (theme) => theme("colors"),
    gridAutoColumns: {
      auto: "auto",
      min: "min-content",
      max: "max-content",
      fr: "minmax(0, 1fr)"
    },
    gridAutoRows: {
      auto: "auto",
      min: "min-content",
      max: "max-content",
      fr: "minmax(0, 1fr)"
    },
    gridColumn: {
      auto: "auto",
      "span-1": "span 1 / span 1",
      "span-2": "span 2 / span 2",
      "span-3": "span 3 / span 3",
      "span-4": "span 4 / span 4",
      "span-5": "span 5 / span 5",
      "span-6": "span 6 / span 6",
      "span-7": "span 7 / span 7",
      "span-8": "span 8 / span 8",
      "span-9": "span 9 / span 9",
      "span-10": "span 10 / span 10",
      "span-11": "span 11 / span 11",
      "span-12": "span 12 / span 12",
      "span-full": "1 / -1"
    },
    gridColumnEnd: {
      auto: "auto",
      1: "1",
      2: "2",
      3: "3",
      4: "4",
      5: "5",
      6: "6",
      7: "7",
      8: "8",
      9: "9",
      10: "10",
      11: "11",
      12: "12",
      13: "13"
    },
    gridColumnStart: {
      auto: "auto",
      1: "1",
      2: "2",
      3: "3",
      4: "4",
      5: "5",
      6: "6",
      7: "7",
      8: "8",
      9: "9",
      10: "10",
      11: "11",
      12: "12",
      13: "13"
    },
    gridRow: {
      auto: "auto",
      "span-1": "span 1 / span 1",
      "span-2": "span 2 / span 2",
      "span-3": "span 3 / span 3",
      "span-4": "span 4 / span 4",
      "span-5": "span 5 / span 5",
      "span-6": "span 6 / span 6",
      "span-full": "1 / -1"
    },
    gridRowStart: {
      auto: "auto",
      1: "1",
      2: "2",
      3: "3",
      4: "4",
      5: "5",
      6: "6",
      7: "7"
    },
    gridRowEnd: {
      auto: "auto",
      1: "1",
      2: "2",
      3: "3",
      4: "4",
      5: "5",
      6: "6",
      7: "7"
    },
    transformOrigin: {
      center: "center",
      top: "top",
      "top-right": "top right",
      right: "right",
      "bottom-right": "bottom right",
      bottom: "bottom",
      "bottom-left": "bottom left",
      left: "left",
      "top-left": "top left"
    },
    gridTemplateColumns: {
      none: "none",
      1: "repeat(1, minmax(0, 1fr))",
      2: "repeat(2, minmax(0, 1fr))",
      3: "repeat(3, minmax(0, 1fr))",
      4: "repeat(4, minmax(0, 1fr))",
      5: "repeat(5, minmax(0, 1fr))",
      6: "repeat(6, minmax(0, 1fr))",
      7: "repeat(7, minmax(0, 1fr))",
      8: "repeat(8, minmax(0, 1fr))",
      9: "repeat(9, minmax(0, 1fr))",
      10: "repeat(10, minmax(0, 1fr))",
      11: "repeat(11, minmax(0, 1fr))",
      12: "repeat(12, minmax(0, 1fr))"
    },
    gridTemplateRows: {
      none: "none",
      1: "repeat(1, minmax(0, 1fr))",
      2: "repeat(2, minmax(0, 1fr))",
      3: "repeat(3, minmax(0, 1fr))",
      4: "repeat(4, minmax(0, 1fr))",
      5: "repeat(5, minmax(0, 1fr))",
      6: "repeat(6, minmax(0, 1fr))"
    },
    height: (theme) => ({
      ...theme("spacing"),
      auto: "auto",
      "1/2": "50%",
      "1/3": "33.333333%",
      "2/3": "66.666667%",
      "1/4": "25%",
      "2/4": "50%",
      "3/4": "75%",
      "1/5": "20%",
      "2/5": "40%",
      "3/5": "60%",
      "4/5": "80%",
      "1/6": "16.666667%",
      "2/6": "33.333333%",
      "3/6": "50%",
      "4/6": "66.666667%",
      "5/6": "83.333333%",
      full: "100%",
      screen: "100vh"
    }),
    inset: (theme, { negative }) => ({
      auto: "auto",
      ...theme("spacing"),
      ...negative(theme("spacing")),
      "1/2": "50%",
      "1/3": "33.333333%",
      "2/3": "66.666667%",
      "1/4": "25%",
      "2/4": "50%",
      "3/4": "75%",
      full: "100%",
      "-1/2": "-50%",
      "-1/3": "-33.333333%",
      "-2/3": "-66.666667%",
      "-1/4": "-25%",
      "-2/4": "-50%",
      "-3/4": "-75%",
      "-full": "-100%"
    }),
    keyframes: {
      spin: {
        to: {
          transform: "rotate(360deg)"
        }
      },
      ping: {
        "75%, 100%": {
          transform: "scale(2)",
          opacity: "0"
        }
      },
      pulse: {
        "50%": {
          opacity: ".5"
        }
      },
      bounce: {
        "0%, 100%": {
          transform: "translateY(-25%)",
          animationTimingFunction: "cubic-bezier(0.8,0,1,1)"
        },
        "50%": {
          transform: "none",
          animationTimingFunction: "cubic-bezier(0,0,0.2,1)"
        }
      }
    },
    letterSpacing: {
      tighter: "-0.05em",
      tight: "-0.025em",
      normal: "0em",
      wide: "0.025em",
      wider: "0.05em",
      widest: "0.1em"
    },
    lineHeight: {
      none: "1",
      tight: "1.25",
      snug: "1.375",
      normal: "1.5",
      relaxed: "1.625",
      loose: "2",
      0: "0",
      3: ".75rem",
      4: "1rem",
      4.5: "1.125rem",
      5: "1.25rem",
      6: "1.5rem",
      6.5: "1.625rem",
      7: "1.75rem",
      7.5: "1.875rem",
      8: "2rem",
      8.5: "2.125rem",
      9: "2.25rem",
      10: "2.5rem",
      10.4: "2.75rem",
      10.5: "2.813rem",
      11: "3.125rem",
      15: "3.75rem"
    },
    listStyleType: {
      none: "none",
      disc: "disc",
      decimal: "decimal"
    },
    lineClamp: {
      1: "1",
      2: "2",
      3: "3",
      4: "4",
      5: "5",
      6: "6"
    },
    margin: (theme, { negative }) => ({
      auto: "auto",
      ...theme("spacing"),
      ...negative(theme("spacing"))
    }),
    maxHeight: (theme) => ({
      ...theme("spacing"),
      full: "100%",
      screen: "100vh",
      "screen-nav": "calc(100vh - 5rem)",
      "screen-nav-sm": "calc(100vh - 8rem)",
      "screen-nav-lg": "calc(100vh - 10rem)"
    }),
    maxWidth: (theme, { breakpoints }) => ({
      none: "none",
      0: "0rem",
      xxs: "17.5rem",
      xs: "20rem",
      sm: "24rem",
      md: "28rem",
      lg: "32rem",
      xl: "36rem",
      "1xl": "40rem",
      "2xl": "42rem",
      "3xl": "48rem",
      "4xl": "56rem",
      "5xl": "64rem",
      "6xl": "72rem",
      "7xl": "80rem",
      "8xl": "87rem",
      full: "100%",
      min: "min-content",
      max: "max-content",
      prose: "65ch",
      420: "420px",
      ...breakpoints(theme("screens"))
    }),
    minHeight: (theme) => ({
      ...theme("spacing"),
      0: "0px",
      full: "100%",
      screen: "100vh",
      xl: "33.125rem"
    }),
    minWidth: (theme) => ({
      ...theme("spacing"),
      0: "0px",
      full: "100%",
      min: "min-content",
      max: "max-content",
      "1xl": "40rem"
    }),
    objectPosition: {
      bottom: "bottom",
      center: "center",
      left: "left",
      "left-bottom": "left bottom",
      "left-top": "left top",
      right: "right",
      "right-bottom": "right bottom",
      "right-top": "right top",
      top: "top"
    },
    opacity: {
      0: "0",
      5: "0.05",
      10: "0.1",
      15: "0.15",
      20: "0.2",
      25: "0.25",
      30: "0.3",
      40: "0.4",
      50: "0.5",
      60: "0.6",
      70: "0.7",
      75: "0.75",
      80: "0.8",
      90: "0.9",
      95: "0.95",
      100: "1"
    },
    order: {
      first: "-9999",
      last: "9999",
      none: "0",
      1: "1",
      2: "2",
      3: "3",
      4: "4",
      5: "5",
      6: "6",
      7: "7",
      8: "8",
      9: "9",
      10: "10",
      11: "11",
      12: "12"
    },
    outline: {
      none: ["2px solid transparent", "2px"],
      white: ["2px dotted white", "2px"],
      black: ["2px dotted black", "2px"]
    },
    padding: (theme) => theme("spacing"),
    placeholderColor: (theme) => theme("colors"),
    placeholderOpacity: (theme) => theme("opacity"),
    ringColor: (theme) => ({
      DEFAULT: theme("colors.blue.500", "#3b82f6"),
      ...theme("colors")
    }),
    ringOffsetColor: (theme) => theme("colors"),
    ringOffsetWidth: {
      0: "0px",
      1: "1px",
      2: "2px",
      4: "4px",
      8: "8px"
    },
    ringOpacity: (theme) => ({
      DEFAULT: "0.5",
      ...theme("opacity")
    }),
    ringWidth: {
      DEFAULT: "3px",
      0: "0px",
      1: "1px",
      2: "2px",
      4: "4px",
      8: "8px"
    },
    rotate: {
      "-180": "-180deg",
      "-90": "-90deg",
      "-45": "-45deg",
      "-12": "-12deg",
      "-6": "-6deg",
      "-3": "-3deg",
      "-2": "-2deg",
      "-1": "-1deg",
      0: "0deg",
      1: "1deg",
      2: "2deg",
      3: "3deg",
      6: "6deg",
      12: "12deg",
      45: "45deg",
      90: "90deg",
      180: "180deg"
    },
    scale: {
      0: "0",
      50: ".5",
      75: ".75",
      90: ".9",
      95: ".95",
      100: "1",
      105: "1.05",
      110: "1.1",
      125: "1.25",
      150: "1.5"
    },
    skew: {
      "-12": "-12deg",
      "-6": "-6deg",
      "-3": "-3deg",
      "-2": "-2deg",
      "-1": "-1deg",
      0: "0deg",
      1: "1deg",
      2: "2deg",
      3: "3deg",
      6: "6deg",
      12: "12deg"
    },
    space: (theme, { negative }) => ({
      ...theme("spacing"),
      ...negative(theme("spacing"))
    }),
    stroke: {
      current: "currentColor"
    },
    strokeWidth: {
      0: "0",
      1: "1",
      2: "2"
    },
    textColor: (theme) => theme("colors"),
    textOpacity: (theme) => theme("opacity"),
    transitionDuration: {
      DEFAULT: "150ms",
      75: "75ms",
      100: "100ms",
      150: "150ms",
      200: "200ms",
      300: "300ms",
      500: "500ms",
      700: "700ms",
      1000: "1000ms"
    },
    transitionDelay: {
      75: "75ms",
      100: "100ms",
      150: "150ms",
      200: "200ms",
      300: "300ms",
      500: "500ms",
      700: "700ms",
      1000: "1000ms"
    },
    transitionProperty: {
      none: "none",
      all: "all",
      DEFAULT:
        "background-color, border-color, color, fill, stroke, opacity, box-shadow, transform",
      colors: "background-color, border-color, color, fill, stroke",
      opacity: "opacity",
      shadow: "box-shadow",
      transform: "transform",
      width: "width",
      height: "height"
    },
    transitionTimingFunction: {
      DEFAULT: "cubic-bezier(0.4, 0, 0.2, 1)",
      linear: "linear",
      in: "cubic-bezier(0.4, 0, 1, 1)",
      out: "cubic-bezier(0, 0, 0.2, 1)",
      "in-out": "cubic-bezier(0.4, 0, 0.2, 1)"
    },
    translate: (theme, { negative }) => ({
      ...theme("spacing"),
      ...negative(theme("spacing")),
      "1/2": "50%",
      "1/3": "33.333333%",
      "2/3": "66.666667%",
      "1/4": "25%",
      "2/4": "50%",
      "3/4": "75%",
      full: "100%",
      "-1/2": "-50%",
      "-1/3": "-33.333333%",
      "-2/3": "-66.666667%",
      "-1/4": "-25%",
      "-2/4": "-50%",
      "-3/4": "-75%",
      "-full": "-100%"
    }),
    width: (theme) => ({
      ...theme("spacing"),
      auto: "auto",
      "1/2": "50%",
      "1/3": "33.333333%",
      "2/3": "66.666667%",
      "1/4": "25%",
      "2/4": "50%",
      "3/4": "75%",
      "1/5": "20%",
      "2/5": "40%",
      "3/5": "60%",
      "4/5": "80%",
      "1/6": "16.666667%",
      "2/6": "33.333333%",
      "3/6": "50%",
      "4/6": "66.666667%",
      "5/6": "83.333333%",
      "1/12": "8.333333%",
      "2/12": "16.666667%",
      "3/12": "25%",
      "4/12": "33.333333%",
      "5/12": "41.666667%",
      "6/12": "50%",
      "7/12": "58.333333%",
      "8/12": "66.666667%",
      "9/12": "75%",
      "10/12": "83.333333%",
      "11/12": "91.666667%",
      full: "100%",
      screen: "100vw",
      min: "min-content",
      max: "max-content"
    }),
    zIndex: (theme, { negative }) => ({
      auto: "auto",
      0: "0",
      10: "10",
      20: "20",
      25: "25",
      30: "30",
      40: "40",
      50: "50",
      60: "60",
      70: "70",
      80: "80",
      90: "90",
      100: "100",
      110: "110",
      ...negative({ 1: "1" })
    }),
    extend: {
      lineHeight: {
        12: "3rem"
      },
      typography: {
        DEFAULT: {
          css: {
            lineHeight: "1.25"
          }
        }
      }
    }
  },
  variantOrder: [
    "first",
    "last",
    "odd",
    "even",
    "visited",
    "checked",
    "group-hover",
    "group-focus",
    "focus-within",
    "hover",
    "focus",
    "focus-visible",
    "active",
    "disabled"
  ],
  variants: {
    accessibility: ["responsive", "focus-within", "focus"],
    alignContent: ["responsive"],
    alignItems: ["responsive"],
    alignSelf: ["responsive"],
    animation: ["responsive"],
    appearance: ["responsive"],
    backgroundAttachment: ["responsive"],
    backgroundClip: ["responsive"],
    backgroundColor: [
      "responsive",
      "dark",
      "group-hover",
      "focus-within",
      "hover",
      "focus",
      "disabled",
      "odd",
      "even"
    ],
    backgroundImage: ["responsive"],
    backgroundOpacity: [
      "responsive",
      "group-hover",
      "focus-within",
      "hover",
      "focus"
    ],
    backgroundPosition: ["responsive"],
    backgroundRepeat: ["responsive"],
    backgroundSize: ["responsive"],
    borderCollapse: ["responsive"],
    borderColor: [
      "responsive",
      "dark",
      "group-hover",
      "focus-within",
      "hover",
      "focus",
      "first",
      "last",
      "disabled"
    ],
    borderOpacity: [
      "responsive",
      "group-hover",
      "focus-within",
      "hover",
      "focus"
    ],
    borderRadius: ["responsive", "odd", "even"],
    borderStyle: ["responsive"],
    borderWidth: ["responsive", "odd", "even"],
    boxShadow: ["responsive", "group-hover", "focus-within", "hover", "focus"],
    boxSizing: ["responsive"],
    clear: ["responsive"],
    container: ["responsive"],
    cursor: ["responsive", "disabled"],
    display: ["responsive"],
    divideColor: ["responsive", "dark"],
    divideOpacity: ["responsive"],
    divideStyle: ["responsive"],
    divideWidth: ["responsive"],
    fill: ["responsive"],
    flex: ["responsive"],
    flexDirection: ["responsive"],
    flexGrow: ["responsive"],
    flexShrink: ["responsive"],
    flexWrap: ["responsive"],
    float: ["responsive"],
    fontFamily: ["responsive"],
    fontSize: ["responsive"],
    fontSmoothing: ["responsive"],
    fontStyle: ["responsive"],
    fontVariantNumeric: ["responsive"],
    fontWeight: ["responsive"],
    gap: ["responsive"],
    gradientColorStops: ["responsive", "dark", "hover", "focus"],
    gridAutoColumns: ["responsive"],
    gridAutoFlow: ["responsive"],
    gridAutoRows: ["responsive"],
    gridColumn: ["responsive"],
    gridColumnEnd: ["responsive"],
    gridColumnStart: ["responsive"],
    gridRow: ["responsive"],
    gridRowEnd: ["responsive"],
    gridRowStart: ["responsive"],
    gridTemplateColumns: ["responsive"],
    gridTemplateRows: ["responsive"],
    height: ["responsive"],
    inset: ["responsive"],
    justifyContent: ["responsive"],
    justifyItems: ["responsive"],
    justifySelf: ["responsive"],
    letterSpacing: ["responsive"],
    lineHeight: ["responsive"],
    listStylePosition: ["responsive"],
    listStyleType: ["responsive"],
    margin: ["responsive", "last", "odd", "even"],
    maxHeight: ["responsive"],
    maxWidth: ["responsive"],
    minHeight: ["responsive"],
    minWidth: ["responsive"],
    objectFit: ["responsive"],
    objectPosition: ["responsive"],
    opacity: ["responsive", "group-hover", "focus-within", "hover", "focus"],
    order: ["responsive"],
    outline: ["responsive", "focus-within", "focus"],
    overflow: ["responsive"],
    overscrollBehavior: ["responsive"],
    padding: ["responsive"],
    placeContent: ["responsive"],
    placeItems: ["responsive"],
    placeSelf: ["responsive"],
    placeholderColor: ["responsive", "dark", "focus"],
    placeholderOpacity: ["responsive", "focus"],
    pointerEvents: ["responsive"],
    position: ["responsive"],
    resize: ["responsive"],
    ringColor: ["responsive", "dark", "focus-within", "focus", "focus-visible"],
    ringOffsetColor: [
      "responsive",
      "dark",
      "focus-within",
      "focus",
      "focus-visible"
    ],
    ringOffsetWidth: ["responsive", "focus-within", "focus", "focus-visible"],
    ringOpacity: ["responsive", "focus-within", "focus", "focus-visible"],
    ringWidth: ["responsive", "focus-within", "focus", "focus-visible"],
    rotate: ["responsive", "hover", "focus"],
    scale: ["responsive", "hover", "focus"],
    skew: ["responsive", "hover", "focus"],
    space: ["responsive"],
    stroke: ["responsive"],
    strokeWidth: ["responsive"],
    tableLayout: ["responsive"],
    textAlign: ["responsive"],
    textColor: [
      "responsive",
      "dark",
      "group-hover",
      "focus-within",
      "hover",
      "focus"
    ],
    textDecoration: [
      "responsive",
      "group-hover",
      "focus-within",
      "hover",
      "focus"
    ],
    textOpacity: [
      "responsive",
      "group-hover",
      "focus-within",
      "hover",
      "focus"
    ],
    textOverflow: ["responsive"],
    textTransform: ["responsive"],
    transform: ["responsive"],
    transformOrigin: ["responsive"],
    transitionDelay: ["responsive"],
    transitionDuration: ["responsive"],
    transitionProperty: ["responsive"],
    transitionTimingFunction: ["responsive"],
    translate: ["responsive", "hover", "focus"],
    userSelect: ["responsive"],
    verticalAlign: ["responsive"],
    visibility: ["responsive"],
    whitespace: ["responsive"],
    width: ["responsive"],
    wordBreak: ["responsive"],
    zIndex: ["responsive", "focus-within", "focus"]
  },
  plugins: [
    require("@tailwindcss/aspect-ratio"),
    require("@tailwindcss/typography"),
    // FIXME typography plugin is only used ONCE, in the swish component, remove?
    plugin(function ({ addComponents }) {
      addComponents({
        ".flip-horizontal": {
          transform: "scaleX(-1)"
        },
        ".text-has-hover-effect": {
          display: "inline",
          backgroundImage: "linear-gradient(currentColor, currentColor)",
          backgroundPosition: "0% 100%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "0% 1px",
          transition: "background-size 0.3s"
        },
        ".text-hover-effect": {
          backgroundSize: "100% 1px"
        },
        ".text-has-hover-effect:hover": {
          backgroundSize: "100% 1px"
        },
        ".image-has-hover-effect": {
          transition: "transform 0.5s cubic-bezier(0.395, 0.005, 0.19, 1)"
        },
        ".image-has-hover-effect:hover": {
          transform: "scale(1.025)"
        },
        ".image-hover-effect": {
          transform: "scale(1.025)"
        },
        ".my-rotate-y-180": {
          transform: "rotateY(180deg)"
        },
        ".preserve-3d": {
          transformStyle: "preserve-3d"
        },
        ".perspective": {
          perspective: "1000px"
        },
        ".backface-hidden": {
          backfaceVisibility: "hidden"
        }
      })
    })
  ]
}
